.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: white;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
  }
  
    .scroller {
      height: 100%;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
  }

  .scroller section {
      scroll-snap-align: start;
  }

  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: #3c005a;
    min-height: 50px;
    margin-bottom: 10px;
  }
  
  .about .prompt {
    padding-top: 10px;
    width: 40%;
    font-size: 30px;
    margin-bottom: 70px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .certifications {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    scroll-snap-type: y mandatory;
    padding-bottom: 100px;
  }

  .certifications h1 {
    color: #3e497a;
  }

  .certifications section {
    scroll-snap-align: start;
  }

  .skills {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    scroll-snap-type: y mandatory;
  }

  .skills section {
    scroll-snap-align: start;
  }
  
  .skills h1 {
    color: #3e497a;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    color: black;
    margin-top: 0px;
  }
  
  .list span {
    font-size: 20px;
  }

  .item-title {
    color: #3c005a;
  }

  .item {
    padding-bottom: 20px;
  }
  
  @media only screen and (max-width: 905px) {
    .about h2 {
      font-size: 60px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }